.navbar-text {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.navbar-text-wrapper {
  flex: 1;
  min-width: 0; /* or some value */
}

.flex-stay {
  flex-grow: 0 !important;
}
