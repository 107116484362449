.m-pointer:hover li:first-child {
  cursor: pointer;
}

.m-pointer li:first-child {
  background: #ddd;
}

.m-pointer {
         background: #ddd;
}
