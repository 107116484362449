.footer {
    padding: 24px 0; }
    .footer.footer-default {
      background-color: #f2f2f2; }
    .footer nav {
      display: inline-block;
      float: left;
      padding-left: 0; }
    .footer ul {
      margin-bottom: 0;
      padding: 0;
      list-style: none; }
      .footer ul li {
        display: inline-block; }
        .footer ul li a {
          color: inherit;
          padding: 0.5rem;
          font-size: 0.8571em;
          text-transform: uppercase;
          text-decoration: none; }
          .footer ul li a:hover {
            text-decoration: none; }
    .footer .copyright {
      font-size: 0.8571em;
      line-height: 1.8; }
    .footer:after {
      display: table;
      clear: both;
      content: " "; }

.full-page > .footer {
     position: absolute;
     z-index: 4; }